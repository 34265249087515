import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { SessionStorage } from "@/store/session-storage";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import TalkMovieGet from "@/store/talk-movie/get";
import TalkMovieDownload from "@/store/talk-movie/download";
import * as TalkMovieAPI from "@/api/talk-movie";
import { DeleteRequest, DownloadRequest } from "@/api/talk-movie/request";
import TalkMovieDelete from "@/store/talk-movie/delete";
import {GetRequest} from "@/api/talk-movie/request";
import {MovieItem} from "@/api/talk-movie/response";
import { Mixins, Prop } from "vue-property-decorator";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import UIDialogPlayer from "@/components/UlDialogPlayer.vue";

import { Item } from '@/store/account/get';
import { saveAs } from "file-saver";

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete , UIDialogPlayer } })
export default class index extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string;
  
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "コミュニケーション";
  headingSub = "Communication";

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // ------------

  // ------------
  // 変動値
  // ------------

  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるユーザー名
  deletingMovieName = "";

  //動画ダイアログの表示有無
  showPlayDialog = false;

  //動画名
  playMovieName="";

  //動画Url
  downloadUrl="";

  //動画playerOption
  playerOption={};

  // 削除されるmovieID
  deletingMovieId = "";

  // ローディングステータス
  isLoading = false;

  // ボタンで切り替えた先の画面の初期化フラグ
  isRestored = false;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  movieTableOptions = DEFAULT_TABLE_OPTIONS;
  // ------------

  /**
   * 現在表示しているページのパンくずリスト
   */
  get breadCrumbs() {
    // 20210120 トーク機能追加 cyber 李 start
    return [
      { text: "コミュニケーション", disabled: true },
      {
        text: "トーク一覧",
        disabled: false,
        to: { name: "communication" }
      },
      { text: "動画一覧", disabled: true }
    ];
    // 20210120 トーク機能追加 cyber 李 end
  }

  /**
   * テーブル検索オプション（UIDataTableコンポーネントに渡す）
   */
  get tableOptions() {
    return this.movieTableOptions;
  }
  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.get();
  }

  /**
   * テーブル検索オプション（UIDataTableコンポーネントに渡す）
   */
  set tableOptions(options: TableOptions) {
    this.movieTableOptions = options;
  }

  /**
   * 現在表示しているページのテーブルヘッダー
   */
  get tableHeaders() {
    return [
      { text: "サムネイル", value: "video", sortable: false},
      { text: "ユーザー名", value: "userName"},
      { text: "投稿日時", value: "createDate" },
      { text: "ダウンロード状況", value: "dlFlg" },
      {
        label: "ダウンロード",
        text: "",
        value: "download",
        sortable: false
      },
      {
        label: "削除",
        text: "",
        value: "delete",
        sortable: false
      }
    ];
  }

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    return TalkMovieGet.getItems;
  }
  /**
   * 検索パラメータの復元
   */
  private async restoreSearchParams() {
    // トーク基本設定店舗
    await TalkMovieGet.restore(
      this.tableOptions as GetRequest
    );
    const request = TalkMovieGet.getGetRequest;

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await this.restoreSearchParams();
    await this.get();
  }

  /**
   * movie情報の一覧を取得する処理
   */
  async get(): Promise<boolean> {
    this.isLoading = true;
    const request = this.tableOptions as GetRequest;
    request.shop_id = this.id; 
    await TalkMovieGet.get(request);
    if (!TalkMovieGet.isSuccess) {
      await Flash.setErrorNow({
        message: TalkMovieGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
    return TalkMovieGet.isSuccess;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return TalkMovieGet.getTotalCount;
  }
  // 20210120 NEW_DEV-743 cyber 李 start
  /**
   * shop_id
   */
  get shop_id() {
    return this.id;
  }

  /**
   * shop_name
   */
  get shop_name() {
    return this.$route.query.shop_name ? this.$route.query.shop_name : "";
  }
  // 20210120 NEW_DEV-743 cyber 李 end
  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TalkMovieGet.clearResponse();
    await TalkMovieDelete.clearResponse();
  }

  get movieItems() {
    return TalkMovieGet.getItems;
  }

  //download
  downloadClickCallback(item: MovieItem) {
    //動画放送
    this.download(item.id.toString());
    this.downloadUrl = item.path;
    item.dlFlg = "1";
    this.playMovieName = item.content;
  }
  /**
   * download情報の一覧を取得する処理
   */
  async download(id: string): Promise<boolean> {
    this.isLoading = true;
    const request = this.tableOptions as DownloadRequest;
    request.id = id; 
    request.shop_id = this.id; 
    const response = await TalkMovieAPI.downloadMovie(request);
    if (response && response.statusCd !== 200) {
      await Flash.setErrorNow({
        message: response.message,
        showReloadButton: true
      } as ErrorAlert);
      await this.get();
      this.isLoading = false; 
      return false;
    }

    const blob = new Blob([response.results.movieData], {
      type: "video/mp4"
    });
    saveAs(blob, response.results.fileName);

    this.isLoading = false; 
    return true;
  }

   /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   */ 
  deleteClickCallback(item: MovieItem) {
    this.showDialog = true;
    this.deletingMovieId = String(item.id);
    this.deletingMovieName = item.content;
  }


  /**
   * 動画の削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (!this.deletingMovieId) {
      return;
    }

    this.isLoading = true;
    const request = this.tableOptions as DeleteRequest;
    request.id = this.deletingMovieId; 
    request.shop_id = this.id; 
    await TalkMovieDelete.delete(request);
    if (TalkMovieDelete.isSuccess) {
      await Flash.setSuccessNow({
        // NEW_DEV-1509 cyber start
        message: "動画を削除しました。",
        // NEW_DEV-1509 cyber end
        consumePath: ""
      } as SuccessAlert);
      await this.get();
    } else {
      await Flash.setErrorNow({
        message: TalkMovieDelete.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }


//トーク一覧転移
  async returnClickCallback() {
    this.$router.push({
      name: "communication"
    });
  }

}
