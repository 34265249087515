import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as MovieShopAPI from "@/api/talk-movie";
import { CommonResponse, isSuccess } from "@/api/response";
import { DeleteRequest } from "@/api/talk-movie/request";
import { GetResponse } from "@/api/talk-movie/response";
import { SessionStorage } from "@/store/session-storage";

const MODULE_NAME = "talk-movie/delete"

/**
 * 公開動画登録更新APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Delete extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;
  getRequest: DeleteRequest = {} as DeleteRequest;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }
  get getItems() {
    if (this.getResponse.results) {
      for(var i=0;i<this.getResponse.results.items.length;i++){
        if(this.getResponse.results.items[i].dlFlg == "1"){
          this.getResponse.results.items[i].dlFlg = "DL済";
        } else {
          this.getResponse.results.items[i].dlFlg = "未DL";
        }
         // 20210127 NEW_DEV-743 cyber 肖 start
         //サムネイル取得
        this.getResponse.results.items[i].imagePath = this.getResponse.results.items[i].path.replace('.mp4','.jpg') ;
         // 20210127 NEW_DEV-743 cyber 肖 end
      }
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getShopId() {
    if (this.getResponse.results && this.getResponse.results.items) {
      return this.getResponse.results.items[0].shopId;
    } else {
      return "";
    }
  }

  get getShopName() {
    if (this.getResponse.results && this.getResponse.results.items) {
      return this.getResponse.results.items[0].name;
    } else {
      return "";
    }
  }


  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  @MutationAction
  async delete(getRequest: DeleteRequest) {
    const getResponse = await MovieShopAPI.deleteMovie(getRequest);
    SessionStorage.setObject(MODULE_NAME, getRequest);
    return {
      getRequest,
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Delete);
