import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/talk-movie/response";
import * as MovieShopAPI from "@/api/talk-movie";
import { isSuccess } from "@/api/response";
import { GetRequest } from "@/api/talk-movie/request";
import { SessionStorage } from "@/store/session-storage";

const MODULE_NAME = "talk-movie/get";

/**
 * 動画一覧情報取得API（/get-movie-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;
  getRequest: GetRequest = {} as GetRequest;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      for(var i=0;i<this.getResponse.results.items.length;i++){
        if(this.getResponse.results.items[i].dlFlg == "1"){
          this.getResponse.results.items[i].dlFlg = "DL済";
        } else {
          this.getResponse.results.items[i].dlFlg = "未DL";
        }
         // 20210127 NEW_DEV-743 cyber 肖 start
         //movieのパス取得
         this.getResponse.results.items[i].videoSrc = this.getResponse.results.items[i].path;
         //サムネイル取得
         this.getResponse.results.items[i].imagePath = this.getResponse.results.items[i].path.replace('.mp4','.jpg');
         // 20210127 NEW_DEV-743 cyber 肖 end
      }
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getShopId() {
    if (this.getResponse.results && this.getResponse.results.items.length > 0) {
      return this.getResponse.results.items[0].shopId;
    } else {
      return "";
    }
  }

  get getShopName() {
    if (this.getResponse.results && this.getResponse.results.items.length > 0) {
      return this.getResponse.results.items[0].name;
    } else {
      return "";
    }
  }


  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await MovieShopAPI.getMovie(getRequest);
    SessionStorage.setObject(MODULE_NAME, getRequest);
    return {
      getRequest,
      getResponse
    };
  }

  @MutationAction
  async restore(initial = {} as GetRequest) {
    const getRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as GetRequest;
    return {
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
