












import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import download from "@/store/talk-movie/download";



@Component
export default class UIDialogPlayer extends Vue {
  @Prop() value!: boolean;
  @Prop() playName!: string;
  @Prop() downloadCallback!: () => void;
  @Prop() playerOptions!: object;
  @Prop() videoSrc!: string;

  @Emit()
  public input(value: boolean) {}
  
  private get localValue(): boolean {
    return this.value;
  }

  private set localValue(value: boolean) {
    this.input(value);
  }
}
