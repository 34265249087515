import service from "@/api/service";
import { GetRequest, RegisterRequest, DownloadRequest, DeleteRequest} from "@/api/talk-movie/request";
import { GetResponse, RegisterResponse } from "@/api/talk-movie/response";
import { CommonResponse, DownloadMovieResponse } from "@/api/response";

/**
 * トーク動画一覧情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function getMovie(getRequest: GetRequest) {
  const response = await service.post("/get-movie-list", getRequest);
  return response.data as GetResponse;
}

/**
 * トーク動画Downloadをコールします。
 *
 * @return DownloadResponse
 */
export async function downloadMovie(downloadRequest: DownloadRequest) {
  const response = await service.postReceiveMovie("/download-movie-shop", downloadRequest);
  return response as DownloadMovieResponse;
}

/**
 * トーク動画Deleteをコールします。
 *
 * @return DeleteResponse
 */
export async function deleteMovie(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-movie-shop", deleteRequest);
  return response.data as GetResponse;
}

